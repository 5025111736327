import httpAxios from '@/utils/http-axios.js'

export default {
    data() {
        return {
            pages: [],
            pageSelected: null
        }
    },
    methods: {
        handlePageFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.pages[this.pageSelected].fileCoverImage = null
            }
            
            this.pages[this.pageSelected].fileCoverImage = files[0]
        },
        handlePageFileMainImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.pages[this.pageSelected].fileImage = null
            }

            this.pages[this.pageSelected].fileImage = files[0]
        },
        handlePageFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.pages[this.pageSelected].fileAudio = null
            }

            this.pages[this.pageSelected].fileAudio = files[0]
        },
        deletePage(index) {
            let self = this
            let totalPages = this.pages.length
            let id = self.pages[index].id ?? null

            if(!id) {
                return self.pages.splice(index, 1)
            }

            if(totalPages <= 1) {
                return this.notifyWarning('Minimum pages is 1!')
            }

            httpAxios({
                url: self.$backendUrl + '/api/v1/fairy-tales-pages/' + id,
                method: 'DELETE'
            }).then(function() {
                self.pages.splice(index, 1)
            })
        },
        appendNewPage() {
            this.pages.push({
                number: this.pages.length + 1,
                title: null,
                content: null,
                cover_image: null,
                image: null,
                audio: null,
                fileCoverImage: null,
                fileImage: null,
                fileAudio: null
            })
        },
        prepareEditPage(index) {
            this.pageSelected = index
        },
        cancelEditPage() {
            this.pageSelected = null
        },
        updatePage() {
            const self = this
            const page = self.pages[self.pageSelected]
            const url = (page.id) ? (self.$backendUrl + '/api/v1/fairy-tales-pages/' + page.id) : (self.$backendUrl + '/api/v1/fairy-tales-pages')
            const formData = new FormData()

            formData.append('number', page.number)
            formData.append('title', page.title)
            formData.append('content', page.content)
            if(page.fileCoverImage) formData.append('cover_image', page.fileCoverImage)
            if(page.fileImage) formData.append('image', page.fileImage)
            if(page.fileAudio) formData.append('audio', page.fileAudio)
            if(page.id) formData.append('_method', 'PUT')
            if(!page.id) formData.append('fairy_tale_id', this.currentFairyTaleId)

            httpAxios({
                url: url,
                method: 'POST',
                data: formData
            }).then(function(response) {
                page.id = response.data.fairy_tale_page.id
                page.cover_image = response.data.fairy_tale_page.cover_image
                page.image = response.data.fairy_tale_page.image
                page.audio = null
                page.audio = response.data.fairy_tale_page.audio

                self.cancelEditPage()
                self.notifySuccess()
            })
        }
    }
}