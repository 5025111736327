import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'
import pagesMixin from './mixins/pages.js'

export default {
    name: 'FairyTalesView',
    data() {
        return {
            fairyTale: {},
            currentFairyTaleId: null,
            fileCoverImage: null
        }
    },
    mixins: [currentRouteMixin, pagesMixin],
    components: {
        adminLayout
    },
    mounted() {
        this.currentFairyTaleId = this.currentRoute().params.id

        this.getFairyTale()
    },
    methods: {
        getFairyTale() {
            const self = this
            const includes = '?include=pages'

            httpAxios({
                url: self.$backendUrl + '/api/v1/fairy-tales/' + self.currentFairyTaleId + includes,
                method: 'GET'
            }).then(function(response) {
                self.fairyTale = response.data.fairy_tale

                if(self.fairyTale.youtube_id) {
                    self.fairyTale.youtube_link = 'https://www.youtube.com/watch?v=' + self.fairyTale.youtube_id
                }

                self.pages = response.data.fairy_tale.pages.map(obj=> ({ ...obj, fileCoverImage: null, fileImage: null, fileAudio: null }))
            })
        },
        deleteFairyTale() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/fairy-tales/' + self.currentFairyTaleId,
                method: 'DELETE'
            }).then(function() {
                self.$router.push({ name: 'admin.fairytales' })
            })
        },
        updateFairyTale() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            formData.append('name', self.fairyTale.name)
            if(self.fairyTale.author) formData.append('author', self.fairyTale.author)
            if(self.fairyTale.youtube_link) formData.append('youtube_link', self.fairyTale.youtube_link)
            if(self.fileCoverImage) formData.append('cover_image', self.fileCoverImage)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/fairy-tales/' + self.currentFairyTaleId,
                method: 'POST',
                data: formData
            }).then(function(response) {
                self.fairyTale = response.data.fairy_tale

                if(self.fairyTale.youtube_id) {
                    self.fairyTale.youtube_link = 'https://www.youtube.com/watch?v=' + self.fairyTale.youtube_id
                }

                self.notifySuccess()
            })
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        }
    }
}